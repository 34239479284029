import compact from 'lodash/fp/compact';

import React from 'react';
import { FormattedMessage, FormattedTime } from 'react-intl';
import Position from '@rio-cloud/rio-uikit/lib/es/Position';
import { copyToClipboard } from '../../../../../components/utils/appsUtils';
import { VehicleDataServices } from '../../mbb-admin-web/components/VehicleData/VehicleDataServices';
import { EMvsGeoStatus, ETssSubscriptionStatus } from '../../mbb-admin-web/components/VehicleData/VehicleData.types';
import { DeviceStateCommon, DeviceStateMBB } from '../device-state.types';
import {
    FieldOrNoData,
    NoData,
    RegistrationMileageCheckFlagOrNoData,
} from '../../../../../components/PropertyTableFieldDefinitions';
import { ColumnDescriptorProps } from '../../../../../components/table/ListTable';
import AuthorisationButton from '../../../../../components/utils/authorisation/authorisationButton';
import { CORECON_MBB_WRITE } from '../../../../../components/utils/roleUtils';

export const deviceStateTBM3DetailsRowDescriptors: ColumnDescriptorProps<DeviceStateCommon>[] = compact([
    {
        id: 'serialNumber',
        field: 'serialNumber',
        label: 'intl-msg:coreconAdminWeb.hwSerial',
        format: (field: any) => [<FieldOrNoData key="fieldOrNoData" field={field} />,
            field &&
            <span key="field" className="rioglyph rioglyph-duplicate text-color-dark text-size-15 padding-left-5"
                  onClick={(event) => {
                      event.stopPropagation();
                      copyToClipboard(field, true);
                  }} />,
        ],
    }, {
        id: 'vin',
        field: 'vin',
        label: 'intl-msg:coreconAdminWeb.vin',
        format: (field: any) => [<FieldOrNoData key="fieldOrNoData" field={field} />,
            field &&
            <span key="field" className="rioglyph rioglyph-duplicate text-color-dark text-size-15 padding-left-5"
                  onClick={(event) => {
                      event.stopPropagation();
                      copyToClipboard(field, true);
                  }} />,
        ],
    }, {
        id: 'previousVin',
        field: 'previousVin',
        label: 'intl-msg:coreconAdminWeb.previousVin',
        format: (field: any) => <FieldOrNoData field={field} />,
    }, {
        id: 'hardwareVariant',
        field: 'hardwareVariant',
        label: 'intl-msg:coreconAdminWeb.hardwareVariant',
        format: (field: any) => <FieldOrNoData field={field} />,
    }, {
        id: 'lastMileage',
        field: 'lastMileage',
        label: 'intl-msg:coreconAdminWeb.mileage',
        format: (field: any) => <FieldOrNoData field={field} />,
    }, {
        id: 'lastMileageDateTime',
        field: 'lastMileageDateTime',
        label: 'intl-msg:coreconAdminWeb.mileageDate',
        format: (field: any) => <FieldOrNoData field={field ? <FormattedTime value={field} year="numeric"
                                                                             month="2-digit"
                                                                             day="2-digit" /> : null} />,
    }, {
        id: 'lastOnlineDateTime',
        field: 'lastOnlineDateTime',
        label: 'intl-msg:coreconAdminWeb.lastMessage',
        format: (field: any) => <FieldOrNoData field={field ? <FormattedTime value={field} year="numeric"
                                                                             month="2-digit"
                                                                             day="2-digit" /> : null} />,
    }, {
        id: 'hardwareRegion',
        field: 'hardwareRegion',
        label: 'intl-msg:coreconAdminWeb.hardwareRegion',
        format: (field: any) => <FieldOrNoData field={field} />,
    }, {
        id: 'vehicleVariant',
        field: 'vehicleVariant',
        label: 'intl-msg:coreconAdminWeb.vehicleVariant',
        format: (field: any) => <FieldOrNoData field={field} />,
    }, {
        id: 'vehicleType',
        field: 'vehicleType',
        label: 'intl-msg:coreconAdminWeb.vehicleType',
        format: (field: any) => <FieldOrNoData field={field} />,
    }, {
        id: 'softwareVersion',
        field: 'softwareVersion',
        label: 'intl-msg:coreconAdminWeb.softwareVersion',
        format: (field: any) => <FieldOrNoData field={field} />,
    }, {
        id: 'tcoInfo',
        field: 'tcoInfo',
        label: 'intl-msg:coreconAdminWeb.tcoInfo',
        format: (field: any) => {
            return (
                <>
                    <div className="display-grid grid-template-columns-2">
                        <div className="text-color-dark margin-right-5">
                            <FormattedMessage id={'intl-msg:coreconAdminWeb.tcoSupplier'} />:
                        </div>
                        <FieldOrNoData field={field?.supplier} />
                    </div>
                    <div className="display-grid grid-template-columns-2">
                        <div className="text-color-dark margin-right-5">
                            <FormattedMessage id={'intl-msg:coreconAdminWeb.tcoVariant'} />:
                        </div>
                        <FieldOrNoData field={field?.variant} />
                    </div>
                    <div className="display-grid grid-template-columns-2">
                        <div className="text-color-dark margin-right-5">
                            <FormattedMessage id={'intl-msg:coreconAdminWeb.tcoHardwareId'} />:
                        </div>
                        <FieldOrNoData field={field?.hardwareNumber} />
                    </div>
                    <div className="display-grid grid-template-columns-2">
                        <div className="text-color-dark margin-right-5">
                            <FormattedMessage id={'intl-msg:coreconAdminWeb.tcoSoftwareId'} />:
                        </div>
                        <FieldOrNoData field={field?.softwareNumber} />
                    </div>
                </>
            );
        },
    }, {
        id: 'builtinPosition',
        field: 'builtinPosition',
        label: 'intl-msg:coreconAdminWeb.builtInInfo',
        format: (field: any) => {
            const latitude = field?.latitude;
            const longitude = field?.longitude;
            return (
                <>
                    <div className="display-grid grid-template-columns-2">
                        <div className="text-color-dark margin-right-5">
                            <FormattedMessage id={'intl-msg:coreconAdminWeb.hwSerial'} />:
                        </div>
                        <FieldOrNoData field={field?.serialNumber} />
                    </div>
                    <div className="display-grid grid-template-columns-2">
                        <div className="text-color-dark margin-right-5">
                            <FormattedMessage id={'intl-msg:coreconAdminWeb.builtInDate'} />:
                        </div>
                        <FieldOrNoData field={field?.positionDateTime ? <FormattedTime
                            value={field?.positionDateTime} year="numeric" month="2-digit" day="2-digit" /> : null} />
                    </div>
                    <div className="display-grid grid-template-columns-2">
                        <div className="text-color-dark margin-right-5">
                            <FormattedMessage id={'intl-msg:coreconAdminWeb.builtInPosition'} />:
                        </div>
                        {(latitude && longitude) ?
                            <a target="_blank"
                               href={`https://share.here.com/l/${latitude},${longitude}`}
                               rel="noreferrer noopener">
                                <Position latitude={parseFloat(latitude)} longitude={parseFloat(longitude)} />
                            </a>
                            : <NoData />
                        }
                    </div>
                    <div className="display-grid grid-template-columns-2">
                        <div className="text-color-dark margin-right-5">
                            <FormattedMessage id={'intl-msg:coreconAdminWeb.positionDate'} />:
                        </div>
                        <FieldOrNoData field={field?.positionDateTime ? <FormattedTime
                            value={field?.positionDateTime} year="numeric" month="2-digit" day="2-digit" /> : null} />
                    </div>
                </>
            );
        },
    }, {
        id: 'mileageCheckSkipped',
        field: 'mileageCheckSkipped',
        label: 'intl-msg:coreconAdminWeb.registrationMileageCheckSkipped',
        format: (field: any) => <RegistrationMileageCheckFlagOrNoData field={field} />,
    }]);

const onClickMVSGeo = (vin: string | undefined, newMVSGeoStatus: EMvsGeoStatus, currentMVSGeoStatus: EMvsGeoStatus) => {
    if (vin) {
        VehicleDataServices.mvsGeoSubscriptionChange(vin, newMVSGeoStatus, currentMVSGeoStatus);
    }
};

const onClickTSSSubscription = (vin: string | undefined,
                                newSubscriptionStatus: ETssSubscriptionStatus,
                                currentSubscriptionStatus: ETssSubscriptionStatus) => {
    if (vin) {
        VehicleDataServices.tssKafkaSubscriptionChange(vin, newSubscriptionStatus, currentSubscriptionStatus);
    }
};

const onClickMFHRegistration = (vin: string | undefined) => {
    if (vin) {
        VehicleDataServices.unregisterFromMFH(vin);
    }
};

const onClickRegistration = (vin: string | undefined) => {
    if (vin) {
        VehicleDataServices.unregisterVin(vin);
    }
};

export const deviceStateMBBDetailsRowDescriptors: ColumnDescriptorProps<DeviceStateMBB>[] = compact([{
    id: 'type',
    field: 'type',
    label: 'intl-msg:coreconAdminWeb.type',
    format: (field: any) => <FieldOrNoData field={field} />,
}, {
    id: 'serialNumber',
    field: 'serialNumber',
    label: 'intl-msg:coreconAdminWeb.deviceId',
    format: (field: any) => [<FieldOrNoData key="fieldOrNoData" field={field} />,
        field && <span key="field" className="rioglyph rioglyph-duplicate text-color-dark text-size-15 padding-left-5"
                       onClick={(event) => {
                           event.stopPropagation();
                           copyToClipboard(field, true);
                       }} />,
    ],
}, {
    id: 'vin',
    field: 'vin',
    label: 'intl-msg:coreconAdminWeb.vin',
    format: (field: any) => [<FieldOrNoData key="fieldOrNoData" field={field} />,
        field && <span key="field" className="rioglyph rioglyph-duplicate text-color-dark text-size-15 padding-left-5"
                       onClick={(event) => {
                           event.stopPropagation();
                           copyToClipboard(field, true);
                       }} />,
    ],
}, {
    id: 'lastOnlineDateTime',
    field: 'lastOnlineDateTime',
    label: 'intl-msg:coreconAdminWeb.lastMessage',
    format: (field: any) => <FieldOrNoData field={field ? <FormattedTime value={field} year="numeric"
                                                                         month="2-digit" day="2-digit" /> : null} />,
}, {
    id: 'mbbRegistryRegistrationStatus',
    field: 'mbbRegistryRegistrationStatus',
    label: 'intl-msg:coreconAdminWeb.deviceRegistrationStatus',
    format: (field: any, item: DeviceStateMBB | undefined) => {
        return <div className="display-flex align-items-center">
            <FieldOrNoData field={field} />
            <div className="display-flex padding-left-10">
                <AuthorisationButton
                    onClick={() => onClickRegistration(item?.vin)} allowedRoles={[CORECON_MBB_WRITE]}>
                    {'Unregister'}
                </AuthorisationButton>
            </div>
        </div>;
    },
}, {
    id: 'mfhDeviceRegistrationStatus',
    field: 'mfhDeviceRegistrationStatus',
    label: 'intl-msg:coreconAdminWeb.mfhDeviceRegistrationStatus',
    format: (field: any, item: DeviceStateMBB | undefined) => {
        return <div className="display-flex align-items-center">
            <FieldOrNoData field={field} />
            <div className="padding-left-10">
                <AuthorisationButton
                    onClick={() => onClickMFHRegistration(item?.vin)} allowedRoles={[CORECON_MBB_WRITE]}>
                    {'Unregister'}
                </AuthorisationButton>
            </div>
        </div>;
    },
}, {
    id: 'tssSubscriptionStatus',
    field: 'tssSubscriptionStatus',
    label: 'intl-msg:coreconAdminWeb.tssSubscriptionStatus',
    format: (field: any, item: DeviceStateMBB | undefined) => {
        return <div className="display-flex align-items-center">
            <FieldOrNoData field={field} />
            <div className="padding-left-10">
                {
                    field === 'UN_SUBSCRIBED' ?
                        <AuthorisationButton
                            onClick={() => onClickTSSSubscription(item?.vin, ETssSubscriptionStatus.SUBSCRIBED, field)}
                            allowedRoles={[CORECON_MBB_WRITE]}>
                            {'Subscribe'}
                        </AuthorisationButton>
                        :
                        <AuthorisationButton
                            onClick={
                                () => onClickTSSSubscription(item?.vin, ETssSubscriptionStatus.UN_SUBSCRIBED, field)
                            }
                            allowedRoles={[CORECON_MBB_WRITE]}>
                            {'Unsubscribe'}
                        </AuthorisationButton>
                }
            </div>
        </div>;
    },
}, {
    id: 'mvsGeoStatus',
    field: 'mvsGeoStatus',
    label: 'intl-msg:coreconAdminWeb.mvsGeoStatus',
    format: (field: any, item: DeviceStateMBB | undefined) => {
        return <div className="display-flex align-items-center">
            <FieldOrNoData field={field} />
            <div className="padding-left-10">
                {
                    field === 'OFF' ?
                        <AuthorisationButton onClick={() => onClickMVSGeo(item?.vin, EMvsGeoStatus.OML_MODE, field)}
                                             allowedRoles={[CORECON_MBB_WRITE]}>
                            {'OML mode >'}
                        </AuthorisationButton>
                        :
                        <AuthorisationButton onClick={() => onClickMVSGeo(item?.vin, EMvsGeoStatus.OFF, field)}
                                             allowedRoles={[CORECON_MBB_WRITE]}>
                            {'Switch off >'}
                        </AuthorisationButton>
                }
            </div>
        </div>;
    },
}, {
    id: 'mileageCheckSkipped',
    field: 'mileageCheckSkipped',
    label: 'intl-msg:coreconAdminWeb.registrationMileageCheckSkipped',
    format: (field: any) => <RegistrationMileageCheckFlagOrNoData field={field} />,
}]);
