import { FormattedMessage } from 'react-intl';
import { Link, NavLink } from 'react-router-dom';

import { DefaultUserMenu } from '@rio-cloud/rio-user-menu-component';
import ApplicationHeader from '@rio-cloud/rio-uikit/lib/es/ApplicationHeader';

import { config } from '../../../config';
import ServiceInfo from './ServiceInfo';
import { AppHeaderProps } from './AppHeader.types';
import { ROUTE_HOME_DEVICE_STATE } from '../context/device-state/deviceState.routes';
import { ROUTE_HOME_UPLOAD } from '../context/upload/upload.routes';
import { ROUTE_HOME_CERTIFICATES } from '../context/certificates/certificates.routes';
import { ROUTE_HOME_OVERVIEW } from '../context/overview/overview.routes';
import { ROUTE_HOME_SIGNING } from '../context/device-signing/signing.routes';
import { ThemeToggler } from './ThemeToggler';
import { isAuthorised } from '../../../components/utils/authorisation/authorisationUtils';
import { CORECON_READ } from '../../../components/utils/roleUtils';
import { useEffect, useState } from 'react';

const navItems = [
    {
        key: 'overview',
        route: (
            <NavLink to={ROUTE_HOME_OVERVIEW}>
                <FormattedMessage id={'intl-msg:coreconAdminWeb.overview'} />
            </NavLink>
        ),
    },
    {
        key: 'journal',
        route: (
            <NavLink to={ROUTE_HOME_UPLOAD}>
                <FormattedMessage id={'intl-msg:coreconAdminWeb.upload.moduleName'} />
            </NavLink>
        ),
    },
    {
        key: 'certificates',
        route: (
            <NavLink to={ROUTE_HOME_CERTIFICATES}>
                <FormattedMessage id={'intl-msg:coreconAdminWeb.serviceCertificates.moduleName'} />
            </NavLink>
        ),
    },
    {
        key: 'signing',
        route: (
            <NavLink to={ROUTE_HOME_SIGNING}>
                <FormattedMessage id={'intl-msg:coreconAdminWeb.deviceSigning.moduleName'} />
            </NavLink>
        ),
    },
];

export const environment = process.env.NODE_ENV === 'production' ? 'production' : 'local';

export const serviceInfoItem = <ServiceInfo />;
export const userMenuItem = <DefaultUserMenu environment={environment} />;

const AppHeader = (props: AppHeaderProps) => {
    const [authorised, setAuthorised] = useState(false);

    useEffect(() => setAuthorised(isAuthorised([CORECON_READ])));

    const deviceStateItem = {
        key: 'deviceState',
        route: (
            <NavLink to={ROUTE_HOME_DEVICE_STATE}>
                <FormattedMessage id={'intl-msg:coreconAdminWeb.deviceState.moduleName'} />
            </NavLink>
        ),
    };

    const updatedNavItems = [...navItems];

    if (authorised) {
        updatedNavItems.splice(1, 0, deviceStateItem);
    }

    return (
        <ApplicationHeader
            label={<FormattedMessage id={'intl-msg:starterTemplate.moduleName'} />}
            homeRoute={<Link to={config.homeRoute || ''} />}
            navItems={updatedNavItems}
            actionBarItems={[ThemeToggler()]}
        />
    );
};

export default AppHeader;
