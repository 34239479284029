import { ReactNode } from 'react';
import { isAuthorised } from './authorisationUtils';

interface AuthorisationWrapperArgs {
    allowedRoles: string[];
    children: ReactNode;
}

const AuthorisationWrapper = ({allowedRoles, children}: AuthorisationWrapperArgs) => {

    return (
        isAuthorised(allowedRoles) ?
            <>
                {children}
            </>
            :
            null
    );
};

export default AuthorisationWrapper;