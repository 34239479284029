import React, { ReactNode } from 'react';
import Button from '@rio-cloud/rio-uikit/Button';
import { isAuthorised } from './authorisationUtils';
import Notification from '@rio-cloud/rio-uikit/Notification';

interface AuthorisationButtonProps {
    onClick: Function;
    allowedRoles: string[];
    children?: ReactNode;
}

const AuthorisationButton =
    ({ onClick, allowedRoles, children }: AuthorisationButtonProps) => {

        const handleClick = (event: React.MouseEvent<HTMLElement>) => {
            if (isAuthorised(allowedRoles)) {
                onClick(event);
            } else {
                event.preventDefault();
                Notification.warning('Not enough permissions to perform that action.');
            }
        };

        return (
            <Button onClick={handleClick}>
                {children}
            </Button>
        );
    };

export default AuthorisationButton;