import './App.css';
import { IntlProvider } from 'react-intl';
import { Routes, Route } from 'react-router-dom';
import { SessionExpiredDialog } from '@rio-cloud/rio-session-expired-info';
import ApplicationLayout from '@rio-cloud/rio-uikit/lib/es/ApplicationLayout';
import NotificationsContainer from '@rio-cloud/rio-uikit/lib/es/NotificationsContainer';

import { DEFAULT_LOCALE } from '../../configuration/lang/lang';
import { getUserProfile, isUserSessionExpired } from '../../configuration/login/loginSlice';
import { useAppDispatch, useAppSelector } from '../../configuration/setup/hooks';
import { getDisplayMessages, getLocale } from '../../configuration/lang/langSlice';
import { DEFAULT_ROUTE } from './routes/routes';
import DefaultRedirect from './routes/DefaultRedirect';
import RouteUpdater from './routes/RouteUpdater';

import { getSessionExpiredAcknowledged, hideSessionExpiredDialog } from './appSlice';
import { getSelectedMenu } from './header/headerSlice';
import AppHeader from './header/AppHeader';
import { DeviceStateHome } from './context/device-state/DeviceStateHome';
import UploadHome from './context/upload/UploadHome';
import { ROUTE_HOME_UPLOAD } from './context/upload/upload.routes';
import { ROUTE_HOME_DEVICE_STATE } from './context/device-state/deviceState.routes';
import { ROUTE_HOME_CERTIFICATES } from './context/certificates/certificates.routes';
import { CertificatesHome } from './context/certificates/CertificatesHome';
import { OverviewHome } from './context/overview/OverviewHome';
import { ROUTE_HOME_SIGNING } from './context/device-signing/signing.routes';
import { SigningHome } from './context/device-signing/SigningHome';
import { SidebarRoutes } from './context/device-sidebar/SidebarRoutes';
import { isAuthorised } from '../../components/utils/authorisation/authorisationUtils';
import { CORECON_READ } from '../../components/utils/roleUtils';

const App = () => {
    const dispatch = useAppDispatch();
    const userLocale = useAppSelector(getLocale);
    const displayMessages = useAppSelector(getDisplayMessages);
    const isSessionExpired = useAppSelector(isUserSessionExpired);
    const sessionExpiredAcknowledged = useAppSelector(getSessionExpiredAcknowledged);
    const selectedMenu = useAppSelector(getSelectedMenu);
    const userProfile = useAppSelector(getUserProfile);

    if (!displayMessages || !userLocale || !userProfile || !userProfile.sub) {
        return null;
    }

    const handleSessionExpiredDialogClose = () => dispatch(hideSessionExpiredDialog);
    const showSessionExpired = isSessionExpired && !sessionExpiredAcknowledged;

    return (
        <IntlProvider defaultLocale={DEFAULT_LOCALE} key={userLocale} locale={userLocale} messages={displayMessages}>
            <ApplicationLayout className={'StarterTemplate'}>
                <ApplicationLayout.Header>
                    <AppHeader selectedMenu={selectedMenu} />
                </ApplicationLayout.Header>
                <ApplicationLayout.Sidebar className="right">
                    <SidebarRoutes />
                </ApplicationLayout.Sidebar>
                <ApplicationLayout.Body>
                    <NotificationsContainer />
                    <SessionExpiredDialog
                        locale={userLocale}
                        onClose={handleSessionExpiredDialogClose}
                        show={showSessionExpired}
                    />
                    <Routes>
                        <Route path={DEFAULT_ROUTE} element={<OverviewHome />} />
                        {
                            isAuthorised([CORECON_READ]) &&
                                <Route path={ROUTE_HOME_DEVICE_STATE} element={<DeviceStateHome />} />
                        }
                        <Route path={ROUTE_HOME_UPLOAD} element={<UploadHome />} />
                        <Route path={ROUTE_HOME_CERTIFICATES} element={<CertificatesHome />} />
                        <Route path={ROUTE_HOME_SIGNING} element={<SigningHome />} />
                        <Route path="*" element={<DefaultRedirect />} />
                    </Routes>
                    <RouteUpdater />
                </ApplicationLayout.Body>
            </ApplicationLayout>
        </IntlProvider>
    );
};

export default App;
