import {DeviceList} from './components/DeviceList';
import {FormattedMessage} from 'react-intl';
import AuthorisationWrapper from '../../../../components/utils/authorisation/authorisationWrapper';
import { CORECON_READ } from '../../../../components/utils/roleUtils';

export const DeviceStateHome = () => {
    return (
        <AuthorisationWrapper allowedRoles={[CORECON_READ]}>
            <h1 key="header"><FormattedMessage id={'intl-msg:coreconAdminWeb.deviceState.moduleName'} /></h1>
            <DeviceList key="deviceList"/>
        </AuthorisationWrapper>
    );
};
