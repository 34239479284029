import {configureStore} from '@reduxjs/toolkit';

import configReducer from './configSlice';
import loginReducer from '../login/loginSlice';
import langReducer from '../lang/langSlice';
import tokenReducer from '../tokenHandling/tokenSlice';
import appReducer from '../../features/app/appSlice';
import sidebarReducer from '../../features/app/context/device-sidebar/sidebarSlice';
import headerReducer from '../../features/app/header/headerSlice';
import {deviceStateMBBApi, deviceStateMBBRegistryApi, deviceStateTBM3Api} from '../../services/deviceStateApi';
import {journalNasysStateApi} from '../../services/journalNasysStateApi';
import {journalStoneridgeStateApi} from '../../services/journalStoneridgeStateApi';
import {journalContinentalStateApi} from '../../services/journalContinentalStateApi';
import {serviceCertificatesStateApi} from '../../services/serviceCertificatesStateApi';
import {nasysResultStateApi, quarterlyDeviceRegistrationStateApi} from '../../services/overviewStateApi';
import {
    deviceCertificateCM4Api,
    deviceCertificateTBMApi,
    deviceSigningInitMonitorStateApi,
    deviceSigningNotificationStateApi,
    deviceSigningStateApi,
    deviceSigningStateCM4Api
} from '../../services/deviceSigningStateApi';
import signingReducer from '../../features/app/context/device-signing/signingSlice';

export const store = configureStore({
    reducer: {
        config: configReducer,
        lang: langReducer,
        app: appReducer,
        sidebar: sidebarReducer,
        signing: signingReducer,
        login: loginReducer,
        tokenHandling: tokenReducer,
        appHeader: headerReducer,
        // Add the generated reducer as a specific top-level slice
        [deviceStateTBM3Api.reducerPath]: deviceStateTBM3Api.reducer,
        [deviceStateMBBApi.reducerPath]: deviceStateMBBApi.reducer,
        [deviceStateMBBRegistryApi.reducerPath]: deviceStateMBBRegistryApi.reducer,
        [journalNasysStateApi.reducerPath]: journalNasysStateApi.reducer,
        [journalStoneridgeStateApi.reducerPath]: journalStoneridgeStateApi.reducer,
        [journalContinentalStateApi.reducerPath]: journalContinentalStateApi.reducer,
        [serviceCertificatesStateApi.reducerPath]: serviceCertificatesStateApi.reducer,
        [deviceSigningStateApi.reducerPath]: deviceSigningStateApi.reducer,
        [deviceSigningInitMonitorStateApi.reducerPath]: deviceSigningInitMonitorStateApi.reducer,
        [deviceSigningNotificationStateApi.reducerPath]: deviceSigningNotificationStateApi.reducer,
        [nasysResultStateApi.reducerPath]: nasysResultStateApi.reducer,
        [quarterlyDeviceRegistrationStateApi.reducerPath]: quarterlyDeviceRegistrationStateApi.reducer,
        [deviceCertificateCM4Api.reducerPath]: deviceCertificateCM4Api.reducer,
        [deviceCertificateTBMApi.reducerPath]: deviceCertificateTBMApi.reducer,
        [deviceSigningStateCM4Api.reducerPath] : deviceSigningStateCM4Api.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([
        deviceStateTBM3Api.middleware,
        deviceStateMBBApi.middleware,
        deviceStateMBBRegistryApi.middleware,
        journalNasysStateApi.middleware,
        journalStoneridgeStateApi.middleware,
        journalContinentalStateApi.middleware,
        serviceCertificatesStateApi.middleware,
        deviceSigningStateApi.middleware,
        deviceSigningInitMonitorStateApi.middleware,
        deviceSigningNotificationStateApi.middleware,
        nasysResultStateApi.middleware,
        quarterlyDeviceRegistrationStateApi.middleware,
        deviceCertificateCM4Api.middleware,
        deviceCertificateTBMApi.middleware,
        deviceSigningStateCM4Api.middleware
    ]),
});

// Infer the `RootState` and `RootDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type RootDispatch = typeof store.dispatch;
